






































































































































import api from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {SignupInfoDto} from "@/api/appService";
import ProvinceCityAreaView, {
  ProvinceCityAreaTownDto
} from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaView.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaContent.vue";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "SignupInfoDtoDetail",
  components: {
    AuditBlock,
    ProvinceCityAreaView,
    ProvinceCityAreaContent,
    AttachmentsView,
  },
  filters: {}
})
export default class ServiceObjectDetail extends Vue {

  dataId?: number;
  detail: SignupInfoDto = {};
  loading = true;
  attachments: any = [];

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.signupInfo
      .get({id: this.dataId})
      .then((res) => {
        this.loading = false;
        this.detail = {...res};
      });
    console.log(this.detail);
  }

  handleDownload(url: string) {
    if (url != null) {
      window.open(url, "_blank")
    }
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
